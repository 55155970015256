export const getTimezone = (lngCode: string) => {
  switch (lngCode) {
    case 'pl':
      return 'Europe/Warsaw';
    case 'de':
      return 'Europe/Berlin';
    case 'cs':
      return 'Europe/Prague';
  }
};
