import { ReactNode } from 'react';
import { Grid, makeStyles, CircularProgress } from '@material-ui/core';
import { InfoTooltip } from 'common/components';
import { TFuncKey, Trans } from 'react-i18next';

export type SummaryRowCssClasses = {
  label: string;
};

interface SummaryRowProps {
  label: string;
  children: ReactNode;
  isLoading?: boolean;
  classes?: Partial<SummaryRowCssClasses>;
  infoTooltip?: TFuncKey;
}

export const SummaryRow = ({
  label,
  children,
  classes: outerClasses,
  isLoading = false,
  infoTooltip = undefined
}: SummaryRowProps) => {
  const classes = useStyle();

  return (
    <Grid container className={classes.group} spacing={2}>
      <Grid item xs={8} sm={7} className={classes.title}>
        <span className={outerClasses?.label || classes.label}>
          {label}
          {infoTooltip && (
            <div className={classes.tooltipWrapper}>
              <div className={classes.tooltip}>
                <InfoTooltip placement="left">
                  <Trans i18nKey={infoTooltip} components={{ p: <p /> }} />
                </InfoTooltip>
              </div>
            </div>
          )}
        </span>
      </Grid>
      <Grid container item xs={4} sm={5} justify="flex-end">
        {isLoading ? <CircularProgress size={13} /> : children}
      </Grid>
    </Grid>
  );
};
const useStyle = makeStyles((theme) => ({
  group: {
    textAlign: 'right',
    fontSize: '1rem',
    lineHeight: '1.5rem'
  },
  title: {
    color: theme.orderSummaryBox.color,
    textAlign: 'left',
    fontSize: 'inherit'
  },
  label: {
    display: 'flex',
    gap: '12px'
  },
  tooltipWrapper: {
    position: 'relative'
  },
  tooltip: {
    position: 'absolute',
    bottom: '-10px'
  }
}));
