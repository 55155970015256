import { Divider, makeStyles, Typography } from '@material-ui/core';
import { ReactNode, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  SummaryRow,
  MealsSection,
  InvoiceSection,
  PaymentInformationSection,
  SubmitSection,
  SummarySection,
  SummarySectionHeader,
  PaymentTypeSection
} from 'common/components';
import { getMealsNamesWithSize } from 'common/utils/getMealsNamesWithSize';
import { country } from 'config';
import { useQuery } from 'react-query';
import { convertKcalToKj } from 'common/utils/convertKcalToKj';
import { calculateCalorific } from 'features/orders/utils/calculateCalorifics';
import { maximumCalorificValueToDisplay } from '../../constants';
import { useDietConfiguration, useOrderPrice } from '../../contexts';
import { CreateDietFormData } from '../../types';
import { getDietLength, getCalendarType, getFirstDeliveryDateForCustomDeliveryDates } from '../../utils';
import { ReactComponent as BagIcon } from './icons/bag.svg';
import { ReactComponent as CheckedCircleIcon } from './icons/icon-checked-circle.svg';
import { AddressDeliverySummary } from './AddressDeliverySummary';
import { SummaryWrapper } from './SummaryWrapper';
import { fetchDictionaryTags } from '../../../../common/api/fetchDictionaryTags';
import { useApi } from '../../../apiProvider';

interface SummaryProps {
  paymentsInformationSection?: boolean;
  cta?: ReactNode;
  footer?: ReactNode;
}
const Summary = ({ paymentsInformationSection = false, cta, footer }: SummaryProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { getApiClient } = useApi();
  const { standardDeliveryAddress, festiveDeliveryAddress } = useDietConfiguration();
  const { pricePerDay, totalPrice, discounts, priceToPay, isLoading } = useOrderPrice();

  const orderPaymentTypes = useQuery({
    queryKey: 'orderPaymentTypes',
    queryFn: () => fetchDictionaryTags('ecommerce_order_payment_type', { apiClient: getApiClient() })
  });

  const {
    sizesData,
    deliveryTimeConfig: { disabledDays, firstDeliveryAt },
    config: { packages, variants }
  } = useDietConfiguration();

  const selectedMeals = useWatch<CreateDietFormData, 'meals'>({ name: 'meals' });
  const selectedDietPackage = useWatch<CreateDietFormData, 'dietPackage'>({ name: 'dietPackage' });
  const selectedDietVariant = useWatch<CreateDietFormData, 'dietVariant'>({
    name: 'dietVariant'
  });
  const firstDeliveryDate = useWatch<CreateDietFormData, 'continuousFirstDeliveryDate'>({
    name: 'continuousFirstDeliveryDate'
  });
  const withoutWeekends = useWatch<CreateDietFormData, 'withoutWeekends'>({
    name: 'withoutWeekends'
  });
  const dietLengthId = useWatch<CreateDietFormData, 'dietLength'>({
    name: 'dietLength'
  });
  const customDeliveryDates = useWatch<CreateDietFormData, 'customDeliveryDates'>({
    name: 'customDeliveryDates'
  });

  const calories = useMemo(() => calculateCalorific(selectedMeals, sizesData), [sizesData, selectedMeals]);
  const dietName = variants.get(selectedDietVariant)?.name ?? '';
  const firstDelivery =
    getCalendarType({ withoutWeekends, dietLengthId }) !== 'custom'
      ? firstDeliveryDate.format('DD.MM.YYYY')
      : getFirstDeliveryDateForCustomDeliveryDates(customDeliveryDates)?.format('DD.MM.YYYY');
  const showWithWeekendsSummary =
    getCalendarType({ withoutWeekends, dietLengthId }) !== 'custom' && country !== 'germany';
  return (
    <SummaryWrapper footer={footer}>
      <Typography variant="h2" align="center">
        {t('creatDietPage.summaryBox.title')}
      </Typography>
      <SummarySection
        icon={BagIcon}
        title={t('creatDietPage.summaryBox.sections.diet.title')}
        collapsedSummary={t('creatDietPage.summaryBox.sections.diet.collapsedSummary', {
          calories,
          dietName,
          firstDelivery
        })}
      >
        <SummaryRow label={t('creatDietPage.summaryBox.calories')}>
          {calories <= maximumCalorificValueToDisplay
            ? t('creatDietPage.summaryBox.caloriesSmall', {
                kj: convertKcalToKj(calories),
                calories
              })
            : t('creatDietPage.summaryBox.caloriesHuge', {
                kj: convertKcalToKj(maximumCalorificValueToDisplay),
                calories: maximumCalorificValueToDisplay
              })}
        </SummaryRow>
        <MealsSection
          names={getMealsNamesWithSize({
            selectedMeals,
            sizesData
          })}
        />
        <SummaryRow label={t('creatDietPage.summaryBox.package')}>{packages.get(selectedDietPackage)?.name}</SummaryRow>
        <SummaryRow label={t('creatDietPage.summaryBox.defaultDiet')}>{dietName}</SummaryRow>
        <SummaryRow label={t('creatDietPage.summaryBox.firstDelivery')}>{firstDelivery}</SummaryRow>
        <SummaryRow label={t('creatDietPage.summaryBox.numberOfDays')}>
          {getDietLength({
            dietLengthId,
            withoutWeekends,
            customDeliveryDates,
            disabledDays,
            firstDeliveryAt,
            firstDeliveryDate
          })}
        </SummaryRow>
        {showWithWeekendsSummary && (
          <SummaryRow label={t('creatDietPage.summaryBox.withWeekends.title')}>
            {t(`creatDietPage.summaryBox.withWeekends.${!withoutWeekends}`)}
          </SummaryRow>
        )}
      </SummarySection>
      {(standardDeliveryAddress || (country === 'poland' && festiveDeliveryAddress)) && (
        <Divider variant="middle" className={classes.divider} />
      )}
      {standardDeliveryAddress && <AddressDeliverySummary data={standardDeliveryAddress} deliveryDays="bussinesDays" />}
      {country === 'poland' && festiveDeliveryAddress && (
        <AddressDeliverySummary data={festiveDeliveryAddress} deliveryDays="weekendDays" />
      )}
      {!orderPaymentTypes.isLoading && (
        <PaymentTypeSection
          orderPaymentTypes={orderPaymentTypes.data || []}
          divider={<Divider variant="middle" className={classes.divider} />}
        />
      )}

      <Divider variant="middle" className={classes.divider} />
      <SummarySectionHeader
        className={classes.priceSummaryHeader}
        icon={CheckedCircleIcon}
        title={t('creatDietPage.summaryBox.sections.priceSummary')}
      />
      <section>
        <SummaryRow label={t('creatDietPage.summaryBox.pricePerDay')} isLoading={isLoading}>
          <strong>{t('formatters.price', { price: pricePerDay })}</strong>
        </SummaryRow>
        <SummaryRow
          label={t('creatDietPage.summaryBox.totalPrice')}
          isLoading={isLoading}
          infoTooltip="creatDietPage.summaryBox.totalPriceTooltip"
        >
          <strong>{t('formatters.price', { price: totalPrice })}</strong>
        </SummaryRow>
        {discounts.delivery !== 0 && (
          <SummaryRow
            classes={{ label: classes.discountLabel }}
            label={t('creatDietPage.summaryBox.deliveryDiscount')}
            isLoading={isLoading}
          >
            <strong className={classes.discountPrice}>- {t('formatters.price', { price: discounts.delivery })}</strong>
          </SummaryRow>
        )}
        {!discounts.message && discounts.code !== 0 && (
          <SummaryRow
            classes={{ label: classes.discountLabel }}
            label={t('creatDietPage.summaryBox.discount')}
            isLoading={isLoading}
          >
            <strong className={classes.discountPrice}>- {t('formatters.price', { price: discounts.code })}</strong>
          </SummaryRow>
        )}
        <SummaryRow label={t('creatDietPage.summaryBox.fromMoneybox')} isLoading={isLoading}>
          <strong>{t('formatters.price', { price: discounts.moneybox })}</strong>
        </SummaryRow>
      </section>
      <Divider variant="middle" className={classes.divider} />
      <section>
        <InvoiceSection />
      </section>
      {paymentsInformationSection && (
        <>
          <Divider variant="middle" className={classes.divider} />
          <section>
            <PaymentInformationSection />
          </section>
        </>
      )}
      {cta && (
        <>
          <Divider variant="middle" className={classes.divider} />
          <section>
            <SubmitSection price={priceToPay} cta={cta} isLoading={isLoading} />
          </section>
        </>
      )}
    </SummaryWrapper>
  );
};

export default Summary;

const useStyle = makeStyles((theme) => ({
  divider: {
    margin: '1rem 0'
  },
  discountLabel: {
    color: theme.customColors.green
  },
  priceSummaryHeader: {
    marginBottom: '1rem'
  },
  discountPrice: {
    color: theme.customColors.green,
    whiteSpace: 'nowrap'
  }
}));
