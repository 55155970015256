import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/pl';
import 'dayjs/locale/de';
import 'dayjs/locale/cs';
import { getTimezone } from './timezone';

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1
});

dayjs.updateLocale('pl', {
  weekdaysShort: 'niedz_pon_wt_śr_czw_pt_sob'.split('_')
});

export const i18nDayjs = (language: string) => {
  dayjs.locale(language);
  dayjs.tz.setDefault(getTimezone(language));
};
