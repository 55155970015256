import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DiscountCode } from 'common/types';
import { Button, TextField } from 'common/components';

import { Alert } from '@material-ui/lab';
import { useOrderPrice } from 'features/orders/contexts';
import iconChecked from './images/icon-checked-circle.svg';
import iconWarning from './images/icon-warning-circle.svg';

interface ActivatedDiscountCodeProps {
  value: DiscountCode;
}

export const ActivatedDiscountCode = ({ value }: ActivatedDiscountCodeProps) => {
  const { t } = useTranslation();
  const { discounts } = useOrderPrice();
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <TextField
        type="text"
        value={value.name}
        InputProps={{
          inputProps: {
            className: classes.input
          },
          readOnly: true,
          endAdornment: <img src={discounts.message ? iconWarning : iconChecked} alt="" />
        }}
      />
      <Button mode="secondary" className={classes.button} disabled>
        {t('creatDietPage.discountCodeSection.activeButton')}
      </Button>
      {discounts.message ? (
        <Alert className={classes.discountMessageAlert} severity="info" color="error" icon={false}>
          {discounts.message}
        </Alert>
      ) : (
        <Alert className={classes.discountTypeAlert} severity="info" icon={false}>
          {`${t('creatDietPage.discountCodeSection.discount')} `}
          {value.discountType === 'AMOUNT' ? t('formatters.price', { price: value.discount }) : `${value.discount} %`}
        </Alert>
      )}
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  input: {
    color: 'currentColor !important',
    opacity: '1 !important'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: `1fr ${theme.spacing(13)}`,
    gap: theme.spacing(2)
  },
  button: {
    height: theme.spacing(5)
  },
  discountTypeAlert: {
    backgroundColor: theme.customColors.calendarBrightYellow,
    color: theme.customColors.black,
    fontWeight: 600,
    borderRadius: '0.313rem',
    gridColumn: '1/-1'
  },
  discountMessageAlert: {
    gridColumn: '1/-1'
  }
}));
