import { createContext, useContext, useEffect } from 'react';

export interface DiscountCodeVerificatorContextShape {
  isLoading: boolean;
  error?: string;
  verify: (discountCode: string) => void;
  defaultValue: string;
}

interface DiscountCodeVerificatorProviderProps extends DiscountCodeVerificatorContextShape {
  children: React.ReactNode;
}

const DiscountCodeVerificatorContext = createContext<DiscountCodeVerificatorContextShape | undefined>(undefined);

export const DiscountCodeVerificatorProvider = ({
  children,
  defaultValue,
  error,
  isLoading,
  verify
}: DiscountCodeVerificatorProviderProps): JSX.Element => {
  useEffect(() => {
    if (defaultValue.length > 0) {
      verify(defaultValue);
    }
  }, [defaultValue, verify]);
  const value = {
    defaultValue,
    isLoading,
    verify,
    error
  };
  return <DiscountCodeVerificatorContext.Provider value={value}>{children}</DiscountCodeVerificatorContext.Provider>;
};

export const useDiscountCodeVerificator = () => {
  const context = useContext(DiscountCodeVerificatorContext);
  if (context === undefined) {
    throw new Error('useDietConfiguration must be used within a DietConfigurationContext');
  }
  return context;
};
