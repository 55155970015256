import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormTextField } from 'common/components';
import { DiscountCodeFormData, discountCodeFormSchema, discountCodeFormMaxFieldsMaxLength } from 'features/orders';

interface DiscountCodeFormProps {
  onSubmit: SubmitHandler<DiscountCodeFormData>;
  error?: string;
  defaultValue?: string;
}

export const DiscountCodeForm = ({ onSubmit, defaultValue = '', error }: DiscountCodeFormProps) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const formMethods = useForm<DiscountCodeFormData>({
    resolver: zodResolver(discountCodeFormSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      value: defaultValue
    }
  });

  useEffect(() => {
    formMethods.reset({
      value: defaultValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (error) {
      formMethods.setError('value', { message: error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form className={classes.wrapper} onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FormTextField
            name="value"
            type="text"
            placeholder={t('creatDietPage.discountCodeSection.inputPlaceholder')}
            InputProps={{
              inputProps: {
                maxLength: discountCodeFormMaxFieldsMaxLength.value,
                className: classes.input
              }
            }}
          />
          <Button mode="secondary" className={classes.button} type="submit">
            {t('creatDietPage.discountCodeSection.submitButton')}
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  input: {
    color: 'currentColor !important',
    opacity: '1 !important'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: `1fr ${theme.spacing(13)}`,
    gap: theme.spacing(2)
  },
  button: {
    height: theme.spacing(5)
  }
}));
