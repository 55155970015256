import { z } from 'zod';

import { discountCodeTypeSchema } from './discountCodeType';
import { discountTypeSchema } from './discountType';

export const DiscountCodeSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: discountCodeTypeSchema,
  discount: z.number(),
  discountType: discountTypeSchema
});

export type DiscountCode = z.infer<typeof DiscountCodeSchema>;
