import { Divider, makeStyles } from '@material-ui/core';
import { PaymentInformationSection, SubmitSection, SummaryRow, SummarySectionHeader } from 'common/components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckedCircleIcon } from 'common/icons/icon-checked-circle.svg';
import { useHomePageConfigurator } from '../contexts/HomePageConfiguratorContext';

export const PriceSummary = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { pricePerDay, totalPrice, priceToPay } = useHomePageConfigurator();
  return (
    <>
      <SummarySectionHeader
        className={classes.priceSummaryHeader}
        icon={CheckedCircleIcon}
        title={t('creatDietPage.summaryBox.sections.priceSummary')}
      />
      <SummaryRow label={t('creatDietPage.summaryBox.pricePerDay')}>
        <strong>{t('formatters.price', { price: pricePerDay })}</strong>
      </SummaryRow>
      <SummaryRow
        label={t('creatDietPage.summaryBox.totalPrice')}
        infoTooltip="creatDietPage.summaryBox.totalPriceTooltip"
      >
        <strong>{t('formatters.price', { price: totalPrice })}</strong>
      </SummaryRow>
      <Divider variant="middle" className={classes.divider} />
      <PaymentInformationSection />
      <SubmitSection price={priceToPay} />
    </>
  );
};

const useStyle = makeStyles({
  priceSummaryHeader: {
    marginBottom: '1rem'
  },
  divider: {
    margin: '1rem 0'
  }
});
