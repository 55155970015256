import { DiscountCodeType, DiscountType } from 'common/types';
import { ApiClient } from 'features/apiProvider';

interface VerifyDiscountCodeRequestProps extends ApiClient {
  discountCode: string;
}

export interface DiscountRequestResponse {
  id: string;
  type: DiscountCodeType;
  error: string;
  error_message: string;
  discount: number;
  discount_type: DiscountType;
}

export const verifyDiscountCode = async ({
  apiClient,
  discountCode
}: VerifyDiscountCodeRequestProps): Promise<DiscountRequestResponse> => {
  return apiClient.patch('frontend/check-discount-code', { json: { discountCode } }).json();
};
